@import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
@import '../node_modules/video.js/dist/video-js.css';
@import "../node_modules/@fontsource/roboto-condensed/400.css";
@import "../node_modules/@fontsource/roboto-condensed/300.css";
@import "../node_modules/@fontsource/roboto-condensed/700.css";

@font-face {
  font-display: block;
  font-family: "bootstrap-icons";
  src: url("../node_modules/bootstrap-icons/font/fonts/bootstrap-icons.woff2") format("woff2"),
  url("../node_modules/bootstrap-icons/font/fonts/bootstrap-icons.woff") format("woff");
}

* {
    font-family: 'Roboto Condensed', sans-serif;
}

.no-scroll {
    overflow: hidden !important;
}

.footerstyle * {
    font-family: 'Roboto Condensed', sans-serif;
}

.button-style * {
    background-color: #C804AC;
    border-radius: 10px;
    color: white;
    font-size: 22px;
    font-weight: 500;
    font-family: 'Roboto Condensed', sans-serif;
    text-decoration: none;
}

button.btn.btn-primary.button-style{
    background-color: #C804AC;
    border:none;
    width: 40%;
}

@media only screen and (max-width: 990px) {
  button.btn.btn-primary.button-style{
    width: auto;
    padding:10px 30px;
  }
}

button.btn.btn-primary.button-style.custom{
    background-color: #C804AC;
    border:none;
}

.text-con *{
    font-family: 'Roboto Condensed', sans-serif;
}

.row>* {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.bgGreen {
  border-radius:20px;
  padding: 20px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  background-color: #CEDFE2;
}

.bgGreen * {

    color: #0A606F;
    font-size: 18px;
    white-space: pre-line;
    font-family: 'Roboto Condensed', sans-serif;
}

.c_title *{
    color: #C804AC;
    font-family: 'Roboto Condensed', sans-serif;
    text-align: center;
}

.error-message {
    font-size: 0.8rem;
    color: #C804AC;
    /*text-transform: uppercase;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);*/
    font-style: italic;
}

.info-message {
  font-size: 0.8rem;
  /*text-transform: uppercase;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);*/
  font-style: italic;
}

a{
    text-decoration: none;
}

iframe, .img {
  border-radius:20px;
}

.background {
  border-radius:20px;
}

.background .img {
  border-radius: 0px;
}

.background div div {
  padding: 0px 10px;
}

.background div:nth-child(1) .img {
  border-radius: 20px 0px 0px 20px;
}

.background div:nth-child(2) .img {
  border-radius: 0px 20px 20px 0px;
}

.video-js, .video-js video, .video-js .vjs-poster {
  border-radius: 20px;
}
